.button {
    display: inline-block;
    border: 1px solid #091733;
    background: rgba(255,255,255,0.2);
    font-size: 12px;
    font-weight: 800;
    color: $color-main;
    letter-spacing: 3px;
    height: 67px;
    line-height: 67px;
    text-align: center;
    padding: 0 30px 0 70px;
    text-transform: uppercase;
    // transition: 0.5s;
    position: relative;
    cursor: pointer;

    span {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            display: block;
            font-size: 28px;
            position: absolute;
            top: -8px;
            left: -40px;
        }
    }

    &:hover {        
        color: $color-alt;
        @include gradient-bg();
        text-decoration: none;
    }

    &__icon {
        position: absolute;
        top: 20px;
        left: 30px;
    }

    &--hover-gradient {
        &:after {
            content: '';
            position: absolute;
            @include gradient-bg();
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            z-index: 0;
            opacity: 0;
            @include transition(opacity);
        }

        &:hover {
            background: transparent;
            &:after {
                opacity: 1;
            }
        }
    }

    &--map {

        span {
            @include icon(map, before)
        }
    }

    &--buildings {

        span {
            @include icon(home, before)
        }
    }

    &--presentation {
        width: 100%;
        margin-top: 20px;

        span {
            @include icon(map, before)
        }
    }
}