.home {
    position: absolute;
    z-index: 105;
    top: 0;
    left: 0;
    @include transition(opacity, visibility);

    &--hidden {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &--visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &__buttons {
        margin: 50px 0 35px;

        .button:first-of-type {
            margin-right: 50px;
        }
    }

    &__link {
        position: relative;

        &:after {
            content: '';
            width: 10px;
            height: 16px;
            background: url('../img/link_arrow.svg') no-repeat;
            position: absolute;
            right: -25px;
            top: 3px;
            transition: 0.3s;
        }

        &:hover {
            text-decoration: none;

            &:after {
                right: -30px;
            }
        }
    }
}