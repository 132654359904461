* {
    box-sizing: border-box;
}

html,
body {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

html {
    font-size: 100%;
}

body,
button,
input {
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    outline: none;
}

body {
    background: $color-alt;
    color: $color-main;
}

img {
    // max-width: 100%;
    // height: auto;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table; 
}

h1 {
    font-size: 72px;
    line-height: 1.3em;
    font-weight: 900;
    margin: 40px 0;
}

p {
    font-size: 14px;
    margin: 0;

    a {
        color: $color-accent;
        text-decoration: none;
    
        &:hover {
            color: $color-accent;
            text-decoration: underline;
        }
    }
}

.main {
    height: 100vh;
    width: 100%;
    background: url(../img/bg.jpg) no-repeat center;
    background-size: cover;

    &__container {
        width: 1600px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;

        &--home {
            justify-content: flex-start;
            padding-top: 200px;
        }
    }
}

ul {
    padding-left: 20px;
    margin: 0;

    li {
        font-size: 14px;
        padding-bottom: 10px;
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #FFF;
    background-image: url(../img/bg.jpg) no-repeat center;
    opacity: 1;
    z-index: 9999;
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    @include transition(opacity, visibility);

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--hidden {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
}

.opacity-change {
    @include transition(opacity 1s); 

    &--show {
        opacity: 1;
    }

    &--hide {
        opacity: 0;
    }
}

#stage {
    width: 200px;
    height: 200px;
    background: red;
    position: absolute;
    top: 40%;
    left: 40%;
}

#lottie-wraper, #city-wraper {
    width: 6000px;
    height: 4500px;
    position: absolute;
    top: -1710px;
    left: -2040px;
}

#lottie, #city {
    background-color:#fff;
    width: 100%;
    height: 100%;
    display:block;
    overflow: hidden;
    transform: translate3d(0,0,0);
    text-align: center;
    opacity: 1;
    // transform: scale(3);
}