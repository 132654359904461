// Bootstrap configuration
$grid-columns: 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
    tn: 0,
    xs: 576px,
    sm: 768px,
    md: 1024px,
    rg: 1200px,
    lg: 1400px,
    xl: 1600px
);

$container-max-widths: (
    xs: 540px,
    sm: 720px,
    md: 960px,
    rg: 1210px,
    xl: 1600px,
);

// Load bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';