.custom-scroll {
    max-height: calc(100vh - 555px);
    height: calc(100vh - 555px);
    overflow: hidden;
    
    &--higher {
        max-height: calc(100vh - 425px);
        height: calc(100vh - 425px);
    }

    &--lower {
        max-height: calc(100vh - 660px);
        height: calc(100vh - 660px);
    }
}

.mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.mCSB_scrollTools {
    right: 10px;
}

.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #e7e8f4;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #ced2e8;
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, 
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_dragger:hover .mCSB_dragger_bar {
    background: #ced2e8 !important;
}