.modal-overlay {
    position:absolute;
    z-index:9999;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#000;
    opacity: 0.8;
}

.modal-background {
    width: 100%;
    height: 100%;
    z-index:10000;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    background: transparent;  
}

.modal-content {
    width: 100%;
    height: 100%;
    background: transparent;
    color: #FFF;
}

.modal-close {
    position: absolute;
    top: 25px;
    right: 30px;
    cursor: pointer;
    display: inline-block;
    color: #FFF;
    z-index: 10001;
    opacity: .7;
    font-size: 18px;
    @include transition(opacity); 

    &:hover {
        opacity: 1;
    }
}

.modal-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 10002;
    opacity: 0;
    pointer-events: none;
    @include transition(opacity);

    &--visible {
        opacity: 1;
    }

    &--hidden {
        opacity: 0;
    }
}

.modal-fade {
    opacity: 0;
    @include transition(opacity);

    &--visible {
        opacity: 1;
    }

    &--hidden {
        opacity: 0;
    }
}

.slider-nav {
    position: absolute;
    top: 50%;
    cursor: pointer;
    display: inline-block;
    color: #FFF;
    z-index: 10001;
    opacity: .7;
    font-size: 18px;
    @include transition(opacity); 

    &--prev {
        left: 30px;
    }

    &--next {
        right: 30px;
    }

    &.slick-disabled {
        opacity: 0;
        pointer-events: none;
    }
}

.modal-slider {
    overflow: hidden;
    height: 100vh;

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }
}

.slick-slide {
    padding: 75px;
    height: 100%;
    
    &:focus {
        outline: none;
    }

    .modal-gallery__content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-gallery__image {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    .modal-gallery__video {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}