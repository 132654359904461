.buildings {
    $root: &;
    position: absolute;
    z-index: 106;
    top: 0;
    left: 0;
    @include transition(opacity, visibility);

    &--hidden {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &--visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
    
    &__back {
        margin: 0 0 40px 26px;

        span {
            cursor: pointer;
            display: inline-block;
            padding-right: 15px;
            @include transition(transform);

            &:hover {
                transform: translateX(-5px);
            }
        }
    }

    &__item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
        padding: 100px 40px 30px;
        position: relative;
        opacity: 0.4;
        text-decoration: none;
        color: $color-main;

        p {
            font-size: 12px;
            position: relative;
            color: #858ec6;
            opacity: 0;

            &:before {
                content: '';
                width: 10px;
                height: 16px;
                background: url('../../dist/img/link_arrow.svg') no-repeat;
                position: absolute;
                left: 115px;
                top: 2px;
            }
        }

        &--active {
            cursor: pointer;
            opacity: 1;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                @include gradient-bg();
                z-index: 0;
                opacity: 0;
                @include transition(opacity);
            }

            &:hover {
                color: $color-alt;
                text-decoration: none;

                &:before {
                    opacity: 1;
                }
    
                p {
                    opacity: 1;
                }

                #{$root}__item-image {
                    opacity: 0;

                    &--hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__item-image {
        position: absolute;
        top: 30px;
        left: 35px;
        opacity: 1;
        @include transition(opacity);

        &--hover {
            opacity: 0;
        }
    }

    &__icon {
        font-size: 40px;
    }

    &__name {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 5px;
        position: relative;
        z-index: 1;
    }
}