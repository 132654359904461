
.video {
    @include transition(opacity);

    &__full {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 14;
        
        @media (min-aspect-ratio: 16/9) {
            width: 100%;
            height: auto; /* actually taller than viewport */
            top: 50%;
            transform: translateY(-50%);
        }

        // @media (max-aspect-ratio: 16/9) {
        //     width: auto; /* actually wider than viewport */
        //     height: 100%;
        //     left: 50%;
        //     transform: translateX(-50%);
        // }   
    }

    &__loader {
        content: '';
        z-index: 15;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 1;
        visibility: visible;
        @include transition(opacity, visibility);

        &--loading {
            opacity: 1;
            visibility: visible;
        }

        &--loaded {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: #ced2e8;
    }

    &__progress-val {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 5px;
        background: #858ec6;
    }

    &__play {
        color: $color-main;
        padding-left: 40px;
        position: relative;
        display: inline-block;
        cursor: pointer;
        
        &:hover {
            text-decoration: none;
            color: $color-main;
        }

        &:before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background: url('../../dist/img/play.svg');
            position: absolute;
            top: -2px;
            left: -2px;
        }

        &--pause {            

            &:before {
                width: 20px;
                height: 20px;
                background: url('../../dist/img/pause.png');
                top: 0;
                left: 0;
            }
        }
    }

    &--show {
        opacity: 1;
    }

    &--hide {
        opacity: 0;
    }
}
