  @function str-replace($string, $search, $replace: "") {
      $index: str-index($string, $search);

      @if $index {
          @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
      }

      @return $string;
  }

  @mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
      $src: null;
      $extmods: (eot: "?", svg: "#"+ str-replace($name, " ", "_"));
      $formats: (otf: "opentype", ttf: "truetype");

      @each $ext in $exts {
          $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
          $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
          $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
      }

      @font-face {
          font-family: quote($name);
          font-style: $style;
          font-weight: $weight;
          src: $src;
      }
  }

  @mixin building-icon($name) {
    $url: "../img/#{$name}.svg";
    $urlFilled: "../img/#{$name}_filled.svg";

    &:before {
        background: url($url) no-repeat;
    }

    &.buildings__item--active:hover {

        &:before {
            background: url($urlFilled) no-repeat;
        }
    }
  }

  @mixin gradient-bg() {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#081670+0,233398+66 */
    background: #081670; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #081670 0%, #233398 66%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #081670 0%,#233398 66%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #081670 0%,#233398 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#081670', endColorstr='#233398',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }