.map {    
    height: 100vh;
    width: 100%;
    // background: url(../img/map_bg.jpg) no-repeat center;
    background-size: cover;

    &--building {
        background-repeat: no-repeat;
        background-position: center;
        background-color: #f2f2f2;
    }

    &__container {
        height: 100%;
        width: calc(100% - 400px);
    }

    &__content {
        width: 100%;
        height: 100%;

        &--video {
            z-index: 5;
            position: absolute;
            top: 0; 
        }
    }

    &__controls {
        position: absolute;
        bottom: 20px;
        right: 420px;
    }

    &__controls-zoom {
        cursor: pointer;

        &--out {
        }

        &--in {
            margin-left: 5px;
        }
    }

    &__filters {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    &__filter {
        display: inline-block;
        background: #e7e8f4;
        border: 1px solid #9da4d1;
        height: 28px;
        line-height: 24px;
        padding: 0 10px;
        color: #c4c8e3;
        font-size: 16px;
        cursor: pointer;

        & + .map__filter {
            margin-left: 10px;
        }

        &.active, &:hover {
            background: #9da4d1;
            color: #e7e8f4;
        }
    }

    &__sidebar {
        background: $color-alt;
        width: 400px;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        border-left: 1px solid $color-border;
        z-index: 100;
    }

    &__sidebar-section {
        padding: 30px 50px;

        &:last-of-type, &--no-border {
            border-bottom: 0;
        }

        &--bg {
            background: #E7E8F4;
            margin-bottom: 30px;
        }

        &--border {
            border-bottom: 2px solid $color-border-light;
        }

        &--header {
            padding: 10px 50px 5px;   
            @include gradient-bg();
            border-bottom: 0;

            h3 {
                color: $color-alt;
                font-size: 20px;
                font-weight: 700;
                margin: 0;

                img {
                    margin-right: 20px;
                }
            }
        }

        &--footer {
            padding: 20px 50px;   
            @include gradient-bg();
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            cursor: pointer;
            color:#9da4d1;
            @include transition(color);

            p {
                font-size: 13px;
                margin: 0;
            }

            &:hover {
                text-decoration: none;
                color: $color-alt;
            }
        }

        &--animation {
            background: #eaf1ff;
            padding: 20px 50px;
            position: relative;
            border-bottom: 0;

            p {
                img {
                    margin-right: 10px;
                }
            }
        }

        &--no-pad {
            padding: 0;
        }
    }

    &__logo {
        display: inline-block;
    }

    &__cc-link {
        float: right;
        margin-top: 23px;

        a {
            font-size: 13px;
            color: #b6bbdd;

            &:hover {
                color: $color-accent;
                text-decoration: none;
            }
        }
    }

    &__heading-2 {
        font-size: 20px;
        font-weight: 800;
        margin: 0 0 15px;
        line-height: 1.4em;
    }

    &__heading-3 {
        font-size: 16px;
        font-weight: 700;
        color: #02061c;
    }

    &__heading-choose {
        margin: 30px 0 20px 50px;

        &--city {
            margin: 30px 0 30px 50px;
        }
    }

    &__paragraph {
        color: #02061c;
        font-size: 14px;
        line-height: 1.6em;
        margin: 0;
    }

    &__category-name {
        background: #eaf1ff;
        padding: 20px 20px 20px 50px;
        border-bottom: 1px solid #ced2e8;
        border-top: 1px solid #ced2e8;
    }

    &__items {
        margin: 0 0 0;
        list-style: none;
        padding: 0;
        border-bottom: 1px solid $color-border;

        &:last-of-type {
            border-bottom: 0;
        }

        &--animations {
            height: 450px;
        }
    }

    &__item {
        @include make_row();
        align-items: center;
        padding: 15px 50px;
        border-bottom: 1px solid $color-border;
        color: $color-main;
        cursor: pointer;
        @include transition(background);

        &:last-of-type {
            border-bottom: 0;
        }

        &:hover {
            background: #eaf1ff;
            color: $color-main;
            text-decoration: none;
            // text-shadow: 1px 0 0 $color-main;
        }
    }

    &__item-link {
        display: flex;
        flex-direction: row;
        width: 100%;

        &:hover {
            text-decoration: none;
        }
    }

    &__item-link-play {
        flex: 0 0 35px;
        width: 35px;
        max-width: 35px;
    }

    &__item-link-text {
        padding-top: 3px;
    }

    &__item-image {
        flex: 0 0 80px;
        width: 80px;
        max-width: 80px;
    }

    &__instructions {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;

        p {
            font-size: 13px;
            color: #9da4d1;
        }
    }

    &__instruction {
        display: inline-block;
        margin: 30px 15px 0;
        vertical-align: middle;

        img {
            margin-bottom: 10px;
        }
    }

    &__pin {
        position: absolute;
        cursor: pointer;
        padding: 5px;
        border-radius: 36px;
        // pointer-events: none;
        z-index: 1;
        transform-origin: left center;

        &:hover {
            z-index: 2; 

            .map__pin-bg {
                opacity: 1;
                width: 100%;
                height: 100%;
            }

            // .map__pin-text {
            //     display: flex;
            //     opacity: 1;
            // }
        }

        &--play {
            padding: 8px 10px;
            border-radius: 30px;
            transform-origin: left top;

            &:hover {
                .map__pin-play {
                    position: absolute;
                    opacity: 0;
                }

                .map__pin-animations-list-container {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                    display: block;
                }
            }

            &.disabled {
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__pin-bg {
        @include gradient-bg();
        height: 72px;
        width: 72px;
        border-radius: 36px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.2s;

        &--play {
            width: 100%;
            height: 0;
            transition: none;
        }
    }

    &__pin-icon {
        width: 62px;
        height: 62px;
        border-radius: 31px;
        border: 1px solid $color-accent;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        position: relative;
        z-index: 3;

        img {
            max-width: 45px;
        }
    }

    &__pin-play {
        position: relative;
        float: left;
        pointer-events: all;

        img {
            width: 40px;
        }
    }

    &__pin-animations {
        position: absolute;
        top: 25px;
        left: 25px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        background: $color-accent;
        color: $color-alt;
        font-size: 11px;
        text-align: center;
        vertical-align: middle;
    }

    &__pin-text {
        border-radius: 36px;
        float: left;
        left: -60px;
        padding-left: 20px;
        padding-right: 40px;
        text-align: left;
        height: 62px;
        flex-direction: column;
        justify-content: center;
        display: none;
        position: relative;
        z-index: 2;
        opacity: 0;

        h3 {
            color: $color-alt;
            font-size: 20px;
            font-weight: 800;
            margin: 0;
        }

        h4 {
            color: $color-alt;
            font-size: 16px;
            font-weight: 800;
            margin: 0;
        }

        p {
            font-size: 13px;
            color: #858ec6;
        }
    }

    &__pin-animations-list-container {
        opacity: 0;
        height: 0;
        visibility: hidden;
        overflow: hidden;
        display: none;
        @include transition(height);
    }

    &__pin-animations-list {
        list-style: none;
        padding: 0;
        margin: 0 10px;
        pointer-events: none;

        li {
            border-bottom: 1px solid #5867c3;
            margin: 0;
            padding: 12px 20px 12px 45px;

            &:last-of-type {
                border: 0;
            }

            span {
                display: inline-block;
                width: 100%;
                font-size: 13px;
                color: rgba(255,255,255,0.8);
                position: relative;

                &:before {
                    content: '';
                    background: url('../img/play.svg');
                    display: block;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: -4px;
                    left: -45px;
                }

                &:hover {
                    text-decoration: none;
                    color: $color-alt;
                }
            }
        }
    }

    &__pin-animation-trigger {
        pointer-events: all;
    }

    &__back {
        margin: 0 0 0;

        &--margin {
            margin: 30px 0 40px 50px;
        }

        a {
            font-size: 12px;
            font-weight: 700;
            color: #9da4d1;
            text-transform: uppercase;
            padding-left: 20px;
            position: relative;
            cursor: pointer;

            &:before {
                content: '';
                background: url('../img/back_small.svg');
                display: block;
                width: 8px;
                height: 13px;
                position: absolute;
                top: 1px;
                left: 0;
                transition: 0.3s;
            }

            &:hover {
                text-decoration: none;
                color: $color-accent;

                &:before {
                    left: -5px;
                }
    
            }
        }
    }

    &__animation-desc {
        > * {
            &:first-child {
                margin-top: 0;
            }
        }

        h2 {
            margin: 30px 0 20px;
            font-size: 20px;
            font-weight: 800;
            margin: 0 0 15px;
            line-height: 1.4em;
        }

        h3 {
            margin: 30px 0 20px;
            font-size: 16px;
            font-weight: 700;
            color: #02061c;
        }
    }

    &__sidebar-animation-wraper {
        margin: 0 30px 0 50px;
    }

    &__footer-back {
        font-size: 13px;

        span {
            padding-left: 20px;
            position: relative;

            &:before {
                content: '';
                background: url('../img/map_light.svg');
                display: block;
                width: 24px;
                height: 24px;
                position: absolute;
                top: -4px;
                left: -10px;
            }
        }
    }
}

.movie-popup {
    $root: &;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity .2s ease-out, visibility .2s ease-out;

    &__sidebar {
        right: -400px;
        transition: right .2s ease-out;
        transition-delay: .2s;
    }

    &.visible {
        opacity: 1;
        pointer-events: all;
        z-index: 101;
        visibility: visible;

        #{$root}__sidebar {
            right: 0;
        }
    }

    &__sidebar-movie {
        right: -400px;
        transition: right .2s ease-out;

        &.active {
            right: 0;
        }
    }
}

.sidebar-loader {
    content: '';
    z-index: 15;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.5);
    background-image: url(../img/loader.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 60px;
    opacity: 1;
    visibility: visible;
    @include transition(opacity, visibility);

    &--loading {
        opacity: 1;
        visibility: visible;
    }

    &--loaded {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}